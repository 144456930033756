import React from 'react';
import classNames from 'classnames';
import { TokenAmountWithChainByAssetV2 } from '@/shared/components/TokenWithChain';
import { type ChainflipAsset } from '@/shared/graphql/generated/explorer/graphql';
import { type SwapRequestOverviewFragment } from '@/shared/graphql/generated/reporting/graphql';
import RightDouble from '@/shared/icons/flip-ui-kit/small/RIghtDouble';
import { ArrowIcon, RefundIcon } from '@/shared/icons/large';
import { TokenAmount } from '@/shared/utils';

export const SwapRouteArrow = ({ className }: { className?: string }) => (
  <div
    className={classNames(
      'relative m-auto h-[24px] w-[24px] rounded-full border-[0.75px] border-cf-gray-3-5 p-1 shadow-[0px_0px_0px_0.75px_#191919] [background:linear-gradient(180deg,rgba(255,255,255,0.06)_-19.26%,rgba(255,255,255,0.00)_60.89%),#252525]',
      className,
    )}
  >
    <RightDouble className="absolute bottom-[2.3px] right-[3px] text-cf-light-2" />
  </div>
);

type SwapStep = { asset: ChainflipAsset; amount?: string; amountUsd?: string };

const getSwapSteps = (
  swapRequest: SwapRequestOverviewFragment,
  sourceAsset: ChainflipAsset,
  destinationAsset: ChainflipAsset,
  showIntermediate = true,
): [SwapStep, SwapStep] | [SwapStep, SwapStep, SwapStep] => {
  const sourceAmount = TokenAmount.fromAsset(swapRequest.ingressAmount, sourceAsset);
  const hasSourceAmount = (sourceAmount && !sourceAmount.eq(0)) || undefined;
  const sourceAmounts = {
    asset: sourceAsset,
    amount: hasSourceAmount && sourceAmount?.toFormatted(),
    amountUsd: hasSourceAmount && (swapRequest.ingressValueUsd ?? undefined),
  };

  const swapOutputAmount = TokenAmount.fromAsset(swapRequest.outputAmount, destinationAsset);
  const hasSwapOutputAmount = (swapOutputAmount && !swapOutputAmount.eq(0)) || undefined;
  const swapOutputAmounts = {
    asset: destinationAsset,
    amount: hasSwapOutputAmount && swapOutputAmount?.toFormatted(),
    amountUsd: hasSwapOutputAmount && (swapRequest.outputValueUsd ?? undefined),
  };

  const egressAmount = TokenAmount.fromAsset(swapRequest.egressAmount, destinationAsset);
  const hasEgressAmount = (egressAmount && !egressAmount.eq(0)) || undefined;
  const egressAmounts = {
    asset: destinationAsset,
    amount: hasEgressAmount && egressAmount?.toFormatted(),
    amountUsd: hasEgressAmount && (swapRequest.egressValueUsd ?? undefined),
  };

  const intermediateAmount = TokenAmount.fromAsset(swapRequest.intermediateAmount, 'Usdc');
  const hasIntermediateAmount = (intermediateAmount && !intermediateAmount.eq(0)) || undefined;
  const intermediateAmounts = {
    asset: 'Usdc' as ChainflipAsset,
    amount: hasIntermediateAmount && intermediateAmount?.toFormatted('numeral', 2),
    amountUsd: hasIntermediateAmount && (swapRequest.intermediateValueUsd ?? undefined),
  };

  const destinationAmounts = hasEgressAmount ? egressAmounts : swapOutputAmounts;
  if (sourceAsset === 'Usdc' || destinationAsset === 'Usdc') {
    return [sourceAmounts, destinationAmounts];
  }

  if (showIntermediate) {
    return [sourceAmounts, intermediateAmounts, destinationAmounts];
  }
  return [sourceAmounts, destinationAmounts];
};

const getRoute = (
  steps: [SwapStep, SwapStep] | [SwapStep, SwapStep, SwapStep],
  status: SwapRequestOverviewFragment['status'],
  swapRequest?: SwapRequestOverviewFragment,
  highlightAssets?: ChainflipAsset[],
) =>
  status === 'REFUND_SUCCESS' && swapRequest ? (
    <div className="flex items-center gap-x-4 px-0.5 py-px">
      <TokenAmountWithChainByAssetV2
        asset={swapRequest.sourceAsset}
        amount={TokenAmount.fromAsset(
          swapRequest.ingressAmount,
          swapRequest.sourceAsset,
        )?.toFormatted()}
        usdAmount={swapRequest.ingressValueUsd ?? undefined}
        amountClass="text-12 text-white font-aeonikMono"
        displayChainLogo
        size="small"
        disabled={highlightAssets && !highlightAssets.includes(swapRequest.sourceAsset)}
      />
      <RefundIcon width={16} className="block text-cf-light-2" />
      <TokenAmountWithChainByAssetV2
        asset={swapRequest.sourceAsset}
        amount={TokenAmount.fromAsset(
          swapRequest.refundAmount,
          swapRequest.sourceAsset,
        )?.toFormatted()}
        usdAmount={swapRequest.refundValueUsd ?? undefined}
        amountClass="text-12 text-white font-aeonikMono"
        displayChainLogo
        size="small"
        disabled={highlightAssets && !highlightAssets.includes(swapRequest.sourceAsset)}
      />
    </div>
  ) : (
    <div className="flex items-center gap-x-4 px-0.5 py-px">
      {steps.map((step, index) => (
        <React.Fragment key={step.asset}>
          <TokenAmountWithChainByAssetV2
            asset={step.asset}
            amount={step.amount}
            usdAmount={step.amountUsd}
            amountClass="text-12 text-white font-aeonikMono"
            displayChainLogo={!(index === 1 && steps.length > 2)}
            size="small"
            disabled={highlightAssets && !highlightAssets.includes(step.asset)}
          />
          <ArrowIcon
            width={16}
            className={classNames(
              'text-cf-light-2',
              index + 1 === steps.length ? 'hidden' : 'block',
            )}
          />
        </React.Fragment>
      ))}
    </div>
  );

export const CompactSwapRoute = ({
  routeInfo: { sourceAsset, destinationAsset },
  swapRequest,
  status,
  highlightAssets,
  showIntermediate = true,
}: {
  routeInfo: {
    sourceAsset: ChainflipAsset;
    destinationAsset: ChainflipAsset;
  };
  swapRequest: SwapRequestOverviewFragment;
  status: SwapRequestOverviewFragment['status'];
  highlightAssets?: ChainflipAsset[];
  showIntermediate?: boolean;
}) => {
  if (!sourceAsset || !destinationAsset) return null;
  const steps = getSwapSteps(swapRequest, sourceAsset, destinationAsset, showIntermediate);

  const route = getRoute(steps, status, swapRequest, highlightAssets);

  return route;
};
