import { type SwapRequestOverviewFragment } from '@/shared/graphql/generated/reporting/graphql';
import { CheckCircleIcon, CloseCircleIcon, RefundIcon, HourglassIcon } from '@/shared/icons/small';

const Status = ({ text, Icon, iconColor }: { text: string; Icon: React.FC; iconColor: string }) => (
  <div className="flex items-center gap-x-1">
    <div className={iconColor}>
      <Icon />
    </div>
    {text}
  </div>
);

const RefundSuccess = () => <Status iconColor="text-cf-blue-2" text="Refunded" Icon={RefundIcon} />;

const SwapSuccess = () => (
  <Status iconColor="text-cf-green-1" text="Success" Icon={CheckCircleIcon} />
);

const SwapFailed = () => <Status iconColor="text-cf-red-1" text="Failed" Icon={CloseCircleIcon} />;

const SwapInProgress = () => (
  <Status iconColor="text-cf-orange-2" text="In progress" Icon={HourglassIcon} />
);

const PartialRefund = () => (
  <Status iconColor="text-cf-blue-2" text="Partially Refunded" Icon={RefundIcon} />
);

export const SwapStatus = ({ status }: { status: SwapRequestOverviewFragment['status'] }) => {
  switch (status) {
    case 'SWAP_FAILED':
      return <SwapFailed />;
    case 'PARTIAL_REFUND':
      return <PartialRefund />;
    case 'REFUND_SUCCESS':
      return <RefundSuccess />;
    case 'SUCCESS':
      return <SwapSuccess />;
    case 'IN_PROGRESS':
    default:
      return <SwapInProgress />;
  }
};
