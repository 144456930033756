import { useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { assetConstants } from '@chainflip/utils/chainflip';
import { brokerAliasMap } from '@chainflip/utils/consts';
import { abbreviate } from '@chainflip/utils/string';
import classNames from 'classnames';
import { AgeWithTooltip, Button, Link, SearchInput } from '@/shared/components';
import { Addresses } from '@/shared/components/Addresses';
import Pill from '@/shared/components/flip-ui-kit/Pill';
import { TableV2 } from '@/shared/components/molecules/TableV2';
import TableRow from '@/shared/components/TableRow';
import { TABLE_POLL_INTERVAL } from '@/shared/constants';
import { type SwapRequestOverviewFragment } from '@/shared/graphql/generated/reporting/graphql';
import { useMobile } from '@/shared/hooks';
import useRemotePagination from '@/shared/hooks/useRemotePagination';
import { ChunksIcon, ClockIcon, DocIcon, TimeBoost, VaultIcon } from '@/shared/icons/small';
import { formatToApproxTime } from '@/shared/utils';
import { getAllSwapsQuery } from '../../graphql/reporting/swaps';
import { CompactSwapRoute } from '../SwapRouteV2';
import { SwapStatus } from '../SwapStatusV2';

export const SwapRow = ({
  swapRequest,
  isMobile,
}: {
  swapRequest: SwapRequestOverviewFragment;
  isMobile: boolean;
}) => {
  const sourceChain = assetConstants[swapRequest.sourceAsset].chain;
  const destinationChain = assetConstants[swapRequest.destAsset].chain;
  const swapPath = `/swaps/${swapRequest.swapRequestNativeId}`;
  const destinationAddress = swapRequest.destinationAddress ?? '';
  const refundAddress = swapRequest.refundAddress ?? '';
  const executedChunks = swapRequest.executedChunks ?? 0;
  const totalChunks = swapRequest.totalChunks ?? 0;
  const { isBoosted, mainBrokerAccountSs58Id, status } = swapRequest;

  if (isMobile) {
    return (
      <TableRow
        href={swapPath}
        className={classNames(
          'transition:ease-in h-[91px] text-xs duration-150 hover:cursor-pointer',
          swapRequest.isInProgress
            ? 'bg-cf-gray-4 hover:bg-[#303030] [&>td]:border-cf-gray-5'
            : 'bg-cf-gray-3 hover:bg-cf-gray-3-5 [&>td]:border-cf-gray-3-5',
        )}
      >
        <td>
          <div className="flex flex-col gap-y-2">
            <div className="flex justify-between">
              <SwapStatus status={status} />
              <div className="flex gap-x-1 font-aeonikMono text-cf-light-2">
                {isBoosted ? <TimeBoost className="text-cf-pink-1" /> : <ClockIcon />}
                {swapRequest.startedBlockTimestamp && (
                  <AgeWithTooltip timestamp={`${swapRequest.startedBlockTimestamp}Z`} />
                )}
              </div>
            </div>
            <div className="flex justify-between">
              <CompactSwapRoute
                routeInfo={{
                  destinationAsset: swapRequest.destAsset,
                  sourceAsset: swapRequest.sourceAsset,
                }}
                swapRequest={swapRequest}
                status={status}
                showIntermediate={false}
              />
            </div>
          </div>
        </td>
      </TableRow>
    );
  }

  return (
    <TableRow
      href={swapPath}
      className={classNames(
        'transition:ease-in h-[91px] text-xs duration-150 hover:cursor-pointer ',
        swapRequest.isInProgress
          ? 'bg-cf-gray-4 hover:bg-[#303030] [&>td]:border-cf-gray-5'
          : 'bg-cf-gray-3 hover:bg-cf-gray-3-5 [&>td]:border-cf-gray-3-5',
      )}
    >
      <td>
        <div className="flex gap-x-4 whitespace-nowrap font-aeonikMono">
          <div className="flex w-[65px] flex-col justify-center gap-y-1">
            <Link href={swapPath} underline noPropagate>
              #{swapRequest.swapRequestNativeId}
            </Link>
            {swapRequest.isCcm && <Pill color="neutral" text="CCM" Icon={DocIcon} />}
          </div>
          <div className="inline-flex">
            <CompactSwapRoute
              routeInfo={{
                destinationAsset: swapRequest.destAsset,
                sourceAsset: swapRequest.sourceAsset,
              }}
              swapRequest={swapRequest}
              status={status}
            />
          </div>
        </div>
      </td>

      <td>
        <Addresses
          destinationAddress={destinationAddress}
          destinationChain={destinationChain}
          refundAddress={refundAddress}
          sourceChain={sourceChain}
        />
      </td>

      <td>
        {mainBrokerAccountSs58Id ? ( // TODO(1.8): Revise this logic when all swaps have brokers
          <Pill
            color="neutral"
            text={
              brokerAliasMap[mainBrokerAccountSs58Id]?.name ?? abbreviate(mainBrokerAccountSs58Id)
            }
          />
        ) : (
          <Pill color="neutral" text="Vault swap" Icon={VaultIcon} />
        )}
      </td>

      <td className="min-w-[140px]">
        <div className="flex flex-col gap-y-1 font-aeonikMedium">
          <SwapStatus status={status} />
          {totalChunks > 1 && (
            <div className="flex gap-x-1">
              <ChunksIcon className="text-cf-light-3" />
              {executedChunks}/{totalChunks}
            </div>
          )}
        </div>
      </td>

      <td
        className="whitespace-nowrap text-right font-aeonikMono text-cf-light-2"
        title={
          swapRequest.startedBlockTimestamp
            ? new Date(swapRequest.startedBlockTimestamp).toLocaleString()
            : 'A few seconds ago'
        }
      >
        <div className="flex flex-col justify-end gap-y-1">
          <AgeWithTooltip timestamp={`${swapRequest.startedBlockTimestamp}Z`} />
          {status === 'SUCCESS' && swapRequest.completedInSeconds && (
            <div className="flex items-center justify-end gap-x-1 font-aeonikMono text-12">
              {swapRequest.isBoosted ? <TimeBoost className="text-cf-pink-1" /> : <ClockIcon />}
              Took&nbsp;
              {formatToApproxTime(swapRequest.completedInSeconds)}
            </div>
          )}
        </div>
      </td>
    </TableRow>
  );
};

export default function LatestSwapsTable({ preview = false }: { preview?: boolean }) {
  const [query, setQuery] = useState('');
  const router = useRouter();
  const isMobile = useMobile();

  const isNumeric = (value: string) => /^-?\d+$/.test(value);

  const {
    data: swapRequests,
    isLoading: swapsByDestinationAddressLoading,
    paginationControls: allSwapsPaginationControls,
  } = useRemotePagination(getAllSwapsQuery, {
    useQueryParam: !preview,
    paginationLimits: [30, 60, 80, 100],
    initialLimit: 30,
    refetchInterval: TABLE_POLL_INTERVAL,
    variables: {
      destinationAddress:
        query && query !== '' && !isNumeric(query) ? query.toLowerCase() : undefined,
      swapRequestNativeId: query && isNumeric(query) ? query : undefined,
    },
    context: {
      clientName: 'reportingService',
    },
  });

  const swaps = swapRequests?.allSwapRequests?.edges ?? [];

  const swapTableRows = useMemo(
    () =>
      swaps
        .slice(0, preview ? 10 : swaps.length)
        .map(({ node }) => <SwapRow key={node.id} swapRequest={node} isMobile={isMobile} />),
    [swapRequests, isMobile],
  );

  const paginationControls = preview ? undefined : allSwapsPaginationControls;

  return (
    <div className="flex w-full flex-col">
      <div
        className={classNames(
          `flex flex-col gap-y-4 lg:flex-row lg:items-center`,
          'rounded-md rounded-b-none border border-b-0 border-cf-gray-4 bg-cf-gray-3 p-4',
          preview ? 'gap-x-4' : 'justify-between',
        )}
      >
        <div className="text-left text-20 text-cf-white">Latest swaps</div>
        {preview ? (
          <Button
            type="secondary-standard"
            size="small"
            iconPos="right"
            onClick={() => router.push('/swaps')}
          >
            View All
          </Button>
        ) : (
          <div className="order-1 ml-auto w-full truncate lg:order-none lg:w-[32rem]">
            <SearchInput
              placeholder="Search by swap ID or destination address"
              value={query}
              onChange={setQuery}
              className="border-cf-gray-5 bg-cf-gray-4 text-cf-light-2"
              placeholderClassName="text-cf-light-2"
            />
          </div>
        )}
      </div>
      <TableV2
        isLoading={swapsByDestinationAddressLoading}
        rows={swapTableRows}
        paginationControls={paginationControls}
        className="rounded-t-none"
        thClassName="py-4 px-4 text-sm text-cf-light-2 bg-cf-gray-3"
        emptyRowsContent="No swaps here yet"
        columns={
          isMobile
            ? ['']
            : ['Swap Details', 'Addresses', 'Source', 'Status', { name: 'Age', alignment: 'right' }]
        }
        tbodyClassName={classNames(isMobile ? '[&>tr>td]:p-3' : '[&>tr>td]:p-4', 'bg-cf-gray-3')}
        hideHeaders={isMobile}
      />
    </div>
  );
}
